<template>
    <div class="share-container" v-loading="loading"  element-loading-text="加載內容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="h1">分享</div>
        <div class="h2">讓更多人一齊打氣！</div>
        <div class="note">打氣次數：{{fundraise.pumpeTimes}}</div>
        <div class="share-links">
<!--            <img src="../../../assets/img/Group1.png" alt="" class="share-link" />-->
<!--            <img src="../../../assets/img/Group2.png" alt="" class="share-link" />-->
<!--            <img src="../../../assets/img/Group3.png" alt="" class="share-link" />-->
<!--            <img src="../../../assets/img/Group4.png" alt="" class="share-link" />-->
            <facebook-button class="share-button--painted" :url="url" :description="description" @click="pumpeTime" />
            <whats-app-button class="share-button--painted" :url="url" @click="pumpeTime" />
            <weibo-button class="share-button--painted" :url="url" :description="description" @click="pumpeTime" />
            <twitter-button class="share-button--painted" :url="url" :description="description" @click="pumpeTime" />
        </div>
        <div class="comment-container"></div>
    </div>
</template>

<script>
    import api from "@/api";
    import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
    import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";
    import WeiboButton from "vue-share-buttons/src/components/WeiboButton";
    import TwitterButton from "vue-share-buttons/src/components/TwitterButton";

export default {
    components: {
        FacebookButton,
        WhatsAppButton,
        WeiboButton,
        TwitterButton
    },
    data(){
        return {
            orginfos:{},
            loading:false,
            url: undefined,
            fundraise: {},
            description: undefined
        }
    },
    computed: {
    },
    watch:{
    },
    created:function(){
        this.url =window.location.href;

        let id =this.$route.params.id
        api.fundraise.getFundraiseDetails(id)
            .then((res) => {
                this.fundraise =res.data;
                this.description = "籌款-" +this.fundraise.project.title;
            }).catch((error) => {

        });
    },
    methods: {
        pumpeTime() {
            api.fundraise.pumpeTime(this.fundraise.id);
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    padding-top: 40px;
    min-height: calc(100vh - 48px);
    @include fr-c-s;
    flex-wrap: wrap;
    .article {
        flex-grow: 1;
        flex-basis: 350px;
        min-width: 350px;
    }
    .share-container {
        flex-grow: 1;
        flex-basis: 200px;
        min-width: 200px;
        max-width: 500px;
    }
}
.share-container {
    margin-top:20px;
    padding: 16px;
    border: 1px solid $green-500;
    & > div {
        margin-bottom: 16px;
    }
    .h1 {
        font-size: 40px;
        font-weight: 600;
        color: $green-500;
    }
    .h2 {
        font-size: 28px;
        font-weight: 600;
        color: $green-500;
    }
    .note {
        font-size: 18px;
        color: $gray-500;
    }
    .share-links {
        @include fr-b-c;
        .share-link {
            width: 20%;
            height: 60px;
            border-radius: 50%;
            background-color: green;
        }
    }
}
.p1{
    font-size:23px;
    color: $green-500;
    font-weight: 600;
    margin-top:5px;
}
.p2{
    font-size:25px;
    color: $green-500;
    font-weight: 600;
}
.p3{
    font-size:15px;
    color: #ccc;
    font-weight: 400;
    margin-top:5px;
}
.org-container {
    margin-top: 20px;
    line-height: 1.3;
    border: 1px solid $red-500;
    padding: 16px;
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 80px auto auto;
    gap: 16px;
    .org-logo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        }
    .org-title-group {
        align-self: flex-end;
        .org-name {
            font-size: 24px;
            color: $green-500;
            font-weight: 600;
        }
        .org-sub-title {
            font-size: 14px;
            color: $green-500;
        }
    }
    .org-serial-number {
        grid-column: 1 / 3;
        font-weight: 600;
        color: $gray-400;
        word-break: break-all;
    }
    .org-detail {
        grid-column: 1 / 3;
        color: $gray-400;
    }
}
</style>