<template>
<div >
    <div class="page-1" :style="{'background-image': 'url('+background_img+')'}">
        <div
            class="title-group"
            :class="{ 'at-bottom': donationProgress >= 0.4 }"
            style="width: 85%;"
        >
            <div class="title">目標金額</div>
            <div class="amount">{{ localeTargetMoney }}</div>
            <div class="button-group" v-if="detail.state ==confirmation_pass">
                <router-link style="text-decoration: none;" :to="'/donate/'+detail.uuid+'/2'">
                <primary-button class="donation-button" radius="lg">
                    立即捐款
                </primary-button>
                </router-link>
                <!-- <button class="watch-button"><a :href="detail.project.projectVideo" class="triangle" target="_blank" style="float:left"></a><a :href="detail.project.projectVideo" style="margin-left:10px; text-decoration:none;color:#fff;" target="_blank">觀看影片</a></button> -->
            </div>
        </div>

        <div
            class="title-group"
            :class="{ 'at-bottom':'donationProgress'}"
            style="width: 15%;text-align:right;"
        >
            <!-- <div class="title">目標金額</div>
            <div class="amount">{{ localeTargetMoney }}</div> -->
            <div class="button-group" style="display: block;float: left;">
                <button class="watch-button"><a :href="detail.project.projectVideo" class="triangle" target="_blank" style="float:left"></a><a :href="detail.project.projectVideo" style="margin-left:10px; text-decoration:none;color:#fff;" target="_blank">觀看影片</a></button>
            </div>
        </div>
    </div>
    <div class="progress-bar-container">
        <div
            class="progress-bar"
            :style="{ '--progress': donationProgress }"
        ></div>
        <div
            class="tooltip-container"
            :style="{ '--progress': donationProgress }"
        >
            <div>現時收到捐款</div>
            <div>{{ localeCurrentMoney }}</div>
        </div>
    </div>
    <div class="page-2">
        <main>
            <h1>{{detail.project.title}}</h1>
            <org-info class="org-info"></org-info>
            <div class="recent-activities-container">
                <div class="tab-group">
                    <!-- 如果被选中则动态增加"selected"的class -->
                    <button :class="situationType =='paypal'?'selected':''" @click="changeSituation('paypal')">捐款情況</button>
                    <button :class="situationType =='dynamic'?'selected':''" @click="changeSituation('dynamic')">患者動態</button>
                </div>
                <div class="activities">
                    <div class="bs-sysMsg" v-if="paypals.length &&situationType=='paypal'">
                        <i class="el-alert__icon el-icon-warning"></i>
                        <div class="msg__content">
                            <el-carousel height="20px" direction="vertical" indicator-position="none" :autoplay="true" :interval="carouselInterval">
                                <el-carousel-item v-for="paypal in paypals" :key="paypal.id">
                                    <a href="javascript:void(0)" class="item">{{paypal.realName}}捐款${{paypal.amount}}</a>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                    <div class="bs-sysMsg" v-else-if="dynamics.length &&situationType=='dynamic'">
                        <i class="el-alert__icon el-icon-warning"></i>
                        <div class="msg__content">
                            <el-carousel height="20px" direction="vertical" indicator-position="none" :autoplay="true" :interval="carouselInterval">
                                <el-carousel-item v-for="dynamic in dynamics" :key="dynamic.id">
                                    <a href="javascript:void(0)" class="item">{{dynamic.title}}  {{dynamic.content}}</a>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                    <!-- 这里用v-for循环渲染数据，要区分两种不同类型的动态（根据设计图猜测） -->
                    <!-- 还没写不同类型动态的样式 -->
                    <!-- <div class="activity" v-if="paypals.length &&situationType=='paypal'" v-for="paypal in paypals">{{paypal.realName}}捐款${{paypal.amount}}</div>
                    <div class="activity" v-if="dynamics.length &&situationType=='dynamic'" v-for="dynamic in dynamics">{{dynamic.title}}  {{dynamic.content}}</div> -->
                </div>
            </div>
            <div class="info">
                <div class="start-date">開始日期：{{detail.project.estimatedStartTime}}</div>
                <div class="end-date" v-if="detail.project.constantProject == 'N'">結束日期：{{detail.project.estimatedFinishTime}}</div>
                <div class="end-date" v-else>結束日期：[/]</div>
                <div class="rest-of-days" v-if="detail.project.constantProject == 'N'">倒計時：{{detail.project.countDown}}</div>
                <div class="rest-of-days" v-else>恆常項目</div>
                <div class="no-of-donation">捐款人數：{{detail.numberDonations}}人</div>
            </div>
            <div class="nav-container">
                <router-link :to="{ name: 'projectContent' }">
                    求助内容
                </router-link>
                <router-link :to="{ name: 'projectInfo' }">
                    基本資料
                </router-link>
                <router-link :to="{ name: 'projectActivities' }">
                    患者動態
                </router-link>
                <router-link :to="{ name: 'projectProvement' }">
                    相關證明
                </router-link>
                <router-link v-if="personal_verify" :to="{ name: 'projectPersonalVerification' }">
                    個人驗證
                </router-link>
                <primary-button class="donation-button" radius="md" v-if="detail.state ==confirmation_pass">
                    <router-link style="color:#fff;" :to="'/donate/'+detail.uuid+'/2'">
                     立即捐款
                     </router-link>
                </primary-button>
            </div>
            <router-view class="content"></router-view>
            <div class="donation-button-container">
                <primary-button class="donation-button" radius="md" v-if="detail.state ==confirmation_pass">
                    <router-link style="text-decoration: none;color:#fff;" :to="'/donate/'+detail.uuid+'/2'">
                        立即捐款
                    </router-link>
                </primary-button>
            </div>
        </main>
        <div class="side-bar">
            <org-info class="org-info"></org-info>
            <share class="org-info"></share>
        </div>
    </div>
</div>
</template>

<script>
import orgInfo from "./components/orgInfo.vue";
import share from "./components/share.vue";
import api from "@/api";
export default {
    components: { orgInfo,share },
    data() {
        return {
            targetMoney: 100000,
            currentMoney: 40000,
            loading:false,
            confirmation_pass: '50010',
            background_img: undefined,
            situationType: 'paypal',
            situationPage: 1,
            situationSize: 200,
            carouselInterval: 3000,
            paypals: [],
            dynamics: [],
            personal_verify: false,
            detail:{
                project:{
                    title:'',
                    project_video:''
                }
            },
        };
    },
    computed: {
        localeCurrentMoney() {
            return `$${this.currentMoney.toLocaleString()}`;
        },
        localeTargetMoney() {
            return `$${this.targetMoney.toLocaleString()}`;
        },
        donationProgress() {
            return this.currentMoney / this.targetMoney;
        },
    },
    mounted: function(){
        this.situationFun()
    },
    methods:{
        times(time){
            var date = new Date(time);
            var y = date.getFullYear();
            var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
            var d = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
            return y+'年'+m+'月'+d+'日';
        },
        fileUrl(file) {
            return process.env.VUE_APP_SERVER +file;
        },
        countDown(val){
             var dates = new Date();  
            var now = dates.getTime();
            var leftTime = val - now;
            if(leftTime > 0){
                var d,h,m,s;  
                if (leftTime>=0) {  
                    d = Math.floor(leftTime/1000/60/60/24);  
                    h = Math.floor(leftTime/1000/60/60%24);  
                    m = Math.floor(leftTime/1000/60%60);  
                    s = Math.floor(leftTime/1000%60);                     
                }  
                if(d === 0){
                return h+"小時";
                }else if(h === 0){
                return m+'分鐘';
                }else if(m === 0){
                return s+'秒';
                }else if(s === 0){
                return '已完結';
                }else{
                return d+"天";
                }
            }else{
                return '已完結';
            }
        },
        getSituations(){
            if(this.situationType =='paypal') {
                api.paypal.getPaypals('?content_type=34&object_id=' + this.detail.id + '&state=1&pageSize='+this.situationSize+'&pageNum='+this.situationPage).then(response => {
                    this.paypals = response.data.results;
                    this.situationPage =response.data.next ? this.situationPage+1 : 1;
                })
            }else if(this.situationType =='dynamic') {
                api.fundraiseDynamic.getFundraiseDynamics('?fundraise=' + this.detail.id + '&status=1&pageSize='+this.situationSize+'&pageNum='+this.situationPage).then(response => {
                    this.dynamics = response.data.results;
                    this.situationPage =response.data.next ? this.situationPage+1 : 1;
                })
            }
        },
        situationFun: function(){
            setInterval(this.getSituations,this.situationSize *this.carouselInterval);
        },
        changeSituation: function (type) {
            this.situationPage =1
            this.situationType =type
            this.getSituations()
        }
    },
    created:function (){
         if(this.$route.name =='projectPersonalVerification'){
             this.personal_verify = true;
         }

         let id = this.$route.params.id
         this.loading = true;
         api.fundraise.getFundraiseDetails(id).then(response => {
            this.detail = response.data;
            this.targetMoney =this.detail.amountRequired;
            this.currentMoney =this.detail.fundraiseAmount;
            this.background_img =this.fileUrl(this.detail.project.image)

            let estimatedStartTime  = response.data.project.estimatedStartTime;
            this.$store.state.orginfo = response.data.agency;
            response.data.project.estimatedStartTime = this.times(response.data.project.estimatedStartTime);

            if(response.data.project.constantProject == "Y"){
                response.data.project.estimatedFinishTime = "";
            }else{
                let projectTime = response.data.project.projectTime;
                if(projectTime > 0){
                    var date = new Date(estimatedStartTime);
                    date = date.setDate(date.getDate() + projectTime);
                    response.data.project.estimatedFinishTime = this.times(date);
                    response.data.project.countDown = this.countDown(date);
                }else{
                    response.data.project.countDown = '已完結';
                    response.data.project.estimatedFinishTime = response.data.project.estimatedStartTime;
                }
            }

            this.$store.state.other_files = response.data.personal.otherFiles;
            this.$store.state.projects = response.data.project;

            this.getSituations();
            this.loading = false;
         }).catch(error=>{
             this.$message.error('數據異常，請重新獲取！');
            this.loading = false;
         });

    }
};
</script>

<style lang="scss" scoped>
/*轮翻消息*/
.bs-sysMsg {
    position: relative;
    display: flex;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    border-radius: 2px;
    color: #e6a23c;
    background-color: #fdf6ec;
    overflow: hidden;
    opacity: 1;
    align-items: center;
    transition: opacity .2s;
}
.bs-sysMsg .msg__content {
    display: table-cell;
    padding: 0 8px;
    width: 100%;
}
.bs-sysMsg .msg__content a.item {
    color: #e6a23c;
    font-size: 14px;
    opacity: 0.75;
}
.bs-sysMsg .msg__content a.item:hover{text-decoration: underline;}
.triangle{
   width:0px;
    height:0px;
    border-left:21px solid #fff;
    border-top:12px solid transparent;
    border-bottom:15px solid transparent;
    margin:10px auto;
}
// 第一頁
.page-1 {
    min-height: calc(100vh - 80px);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 80px;
    position: relative;
    @include fr-s-s;
    padding: 32px;
}
.title-group {
    line-height: 1.2;
    &.at-bottom {
        align-self: flex-end;
    }
    .title {
        font-size: 35px;
        color: $white;
    }
    .amount {
        font-size: 46px;
        color: $white;
    }
    .button-group {
        margin-top: 16px;
        @include fr-s-e;
        .donation-button {
            font-size: 24px;
            line-height: 60px;
            padding: 0 40px;
            flex-grow: 3;
            height: 60px;
            margin-right: 32px;
            line-height: 50%;
        }
        .watch-button {
            cursor: pointer;
            box-sizing: border-box;
            font-size: 18px;
            line-height: 46px;
            padding: 0 16px;
            flex-grow: 1;
            color: $white;
            border-radius: 8px;
            border: 2px solid $white;
            height: 50px;
        }
    }
}
.progress-bar-container {
    --progress-bar-height: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100vh - var(--progress-bar-height));
    height: var(--progress-bar-height);
    z-index: 500;
    .progress-bar {
        display: block;
        border-radius: 0 calc(var(--progress-bar-height) / 2)
            calc(var(--progress-bar-height) / 2) 0;
        background-color: $red-500;
        width: calc(100% * var(--progress));
        height: var(--progress-bar-height);
    }
    .tooltip-container {
        position: absolute;
        width: 130px;
        height: 100px;
        padding-top: 20px;
        bottom: var(--progress-bar-height);
        left: MIN(
            MAX(calc(100% * var(--progress) - 65px), 0px),
            calc(100% - 130px)
        );
        background-image: url("../../assets/img/tooltip-background.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        & > div {
            color: $red-500;
            text-align: center;
            line-height: 1.2;
        }
    }
}
// 第二頁
.page-2 {
    padding: 16px;
    @include fr-c-st;
    main {
        & > * {
            margin-bottom: 16px;
        }
        flex-grow: 1;
        .org-info {
            display: none;
        }
    }
    .side-bar {
        flex-shrink: 0;
        margin-left: 16px;
        width: 300px;
        @include fc-s-st;
    }
}
.recent-activities-container {
    background: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.8) 20%,
        transparent 100%
    );
    width: 316px;
    height: calc(100vh - 80px);
    position: absolute;
    top: 80px;
    right: 0;
    padding-right: 16px;
    @include fc-s-st;
    .tab-group {
        button {
            cursor: pointer;
            display: inline-block;
            width: 50%;
            font-size: 24px;
            padding: 8px;
            text-align: center;
            color: $white;
            &.selected {
                font-weight: 600;
            }
        }
    }
    .activities {
        flex-grow: 1;
        overflow: hidden;
        padding: 8px;
        @include fc-s-st;
    }
    .activity {
        padding: 8px;
        border-radius: 8px;
        background-color: $red-500;
        color: $white;
    }
}
.nav-container {
    @include fr-s-c;
    a {
        display: block;
        text-decoration: none;
        color: $gray-300;
        font-size: 20px;
        margin: 0 16px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        font-weight: 600;
        &:not([class*="donation-button"]) {
            padding: 4px 0;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            color: $gray-900;
            border-bottom-color: $gray-900;
        }
    }
    .donation-button {
        margin-left: auto;
        color: $white;
        font-size: 16px;
        flex-grow: 1;
        max-width: 180px;
    }
}
h1 {
    font-size: 32px;
    color: $green-500;
    font-weight: 600;
    line-height: 1.5;
}
.info {
    @include fr-s-c;
    background-color: $green-100;
    padding: 16px;
    & > div {
        color: $gray-400;
        line-height: 1.2;
        margin: 0 8px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .no-of-donation {
        margin-left: auto;
    }
}
.donation-button-container {
    @include fr-c-c;
    .donation-button {
        width: 80%;
        max-width: 300px;
        font-size: 16px;
    }
}
// 自适应
@media screen and (max-width: 900px) {
    // 第一页
    .page-1 {
        min-height: 0;
        max-height: 57vw;
        height: 100vh;
        padding: 8px;
    }
    .title-group {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        &.at-bottom {
            align-self: flex-start;
        }
        .title {
            font-size: 36px;
            line-height: 1.5;
        }
        .amount {
            font-size: 48px;
            line-height: 1.5;
        }
        .button-group {
            margin: 0;
            align-self: center;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            justify-content: flex-end;
            .donation-button {
                display: none;
            }
            .watch-button {
                max-width: 200px;
                line-height: 36px;
                height: 36px;
                border: 1px solid $white;
            }
        }
    }
    .progress-bar-container {
        display: none;
    }
    // 第二页
    .page-2 {
        main {
            @include fc-s-st;
            .org-info {
                display: block;
            }
            .recent-activities-container {
                position: relative;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: auto;
                width: 100%;
            }
        }
        .side-bar {
            display: none;
        }
    }
    .info {
        flex-wrap: wrap;
        & > div,
        .no-of-donation {
            margin: 0;
            width: 100%;
        }
    }
    .nav-container {
        @include fr-b-c;
        a {
            font-size: 16px;
            margin: 0 4px;
        }
        .donation-button {
            display: none;
        }
    }
    .recent-activities-container {
        background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.8) 0%,
                rgba(0, 0, 0, 0.8) unquote("min(10%, 40px)"),
                transparent 100%
            ),
            url("../../assets/img/project-banner-background.png");
        background-size: cover;
        background-position: center;
        padding-right: 0;
        .tab-group {
            button {
                width: auto;
                font-size: 18px;
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .title-group {
        .title {
            font-size: 24px;
        }
        .amount {
            font-size: 36px;
        }
        .button-group {
            .watch-button {
                font-size: 16px;
                line-height: 28px;
                height: 28px;
            }
        }
    }
}
</style>