<template>
    <div v-loading="loading"  element-loading-text="加載內容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="org-containers">
            <p style="margin-top:15px;">檔案編號</p>
            <p style="text-align:right;margin-top:15px;"><a @click="handleAdd" style="color:red">發起舉報</a></p>
        </div>
        <div style="padding: 6px 16px">
            <p style="margin:0 auto 10px;font-size: 12px;">{{uuid}}</p>
        </div>
        <div class="org-container">
            <img :src="fileUrl(orginfos.avatar)" alt="" class="org-logo" />
            <div class="org-title-group">
                <div class="org-name">{{orginfos.name}}</div>
                <div class="org-sub-title">已發起{{orginfos.fundraisesCount}}個項目·{{orginfos.totalNumberDonations}}個支持者</div>
            </div>
            <div class="org-serial-number" style="font-size: 14px;">
                慈善牌照編號：{{orginfos.businessNumber}}
            </div>
            
            <div class="org-serial-number" style="font-size: 14px;">
                {{orginfos.introduction}}
            </div>
        </div>

        <el-dialog title="發起舉報" v-model="dialogFormVisible">
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item prop="real_name">
                    <el-input v-model="form.real_name" placeholder="請輸入名字" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="email">
                    <el-input v-model="form.email" style="margin-top: 10px;" placeholder="請輸入郵件" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="mobile_number">
                    <el-input v-model="form.mobile_number" style="margin-top: 10px;" placeholder="請輸入電話號碼" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="report_message">
                    <el-input type="textarea" v-model="form.report_message" style="margin-top: 10px;" :rows="2" placeholder="請輸入舉報訊息"></el-input>
                </el-form-item>
                <el-form-item prop="report_related_files">
                    <el-upload
                    class="upload-demo"
                    multiple
                    action="#"
                    :limit="3"
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    :on-preview="handlePreview"
                    :on-remove="(file, fileList)=>{return otherHandleRemove(file, fileList)}"
                    :on-exceed="handleExceed"
                    :http-request="requestUpload"
                    :show-file-list="true"
                    :before-upload="beforeAvatarUpload"
                    :file-list="form.report_related_files">
                    <el-button size="small" type="primary">點擊上傳</el-button>
                    </el-upload>
                    <div slot="tip" class="el-upload__tip">請上傳相關資料（只能上傳jpg/png/pdf文件，且不超過500kb）</div>
                </el-form-item>
            </el-form>
            <div slot="footer" style="text-align: center;margin-top: 35px;">
                <el-button type="danger" @click="submitForm" round>進行舉報</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from "@/api";
export default {
    data(){
        return {
            orginfos:{},
            dialogFormVisible :false,
            fundraise_id: undefined,
            uuid: undefined,
            form: {
                fundraise_id: undefined,
                real_name: undefined,
                email: undefined,
                mobile_number: undefined,
                report_message: undefined,
                report_related_files: []
            },
            loading:false,
            // 表单校验
            rules: {
                real_name: [
                    { required: true, message: "名字不能為空", trigger: "blur" }
                ],
                email: [
                    { required: true, message: "郵件不能為空", trigger: "blur" },
                    { type: 'email', message: "郵件格式錯誤", trigger: "blur" }
                ],
                mobile_number: [
                    { required: true, message: "電話號碼不能為空", trigger: "blur" },
                    { pattern: /^[1][3-9]\d{9}$|^([5|6|9])\d{7}$|^[6]\d{7}$/, message: "電話號碼格式錯誤", trigger: "blur" }
                ],
                report_message: [
                    { required: true, message: "舉報訊息不能為空", trigger: "blur" }
                ],
                report_related_files: [
                    { required: true, message: "相關資料不能為空", trigger: "blur" }
                ]
            },
        }
    },
    computed: {
        orginfo(){
            return this.$store.state.orginfo;
        }
    },
    watch:{
        orginfo(){
            this.orginfos = this.$store.state.orginfo;
            this.loading = false;
        }
    },
    created:function(){
        this.loading = true;
        let id = this.$route.params.id;
        api.fundraise.getFundraiseDetails(id).then(response => {
            this.fundraise_id =response.data.id;
            this.uuid =response.data.uuid;
        }).catch(error=>{
            this.$message.error('基本資料數據異常，請重新獲取！');
        });
    },
    methods: {
        dialogFormVisibles(){
            console.log(this.other_files);
            // this.$message.success('本平臺已收到閣下的舉報');
            // this.dialogFormVisible = false;
        },
      requestUpload(){

      },
        fileUrl(file) {
            return process.env.VUE_APP_SERVER +file;
        },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      handleAdd: function() {
          this.dialogFormVisible = true
          this.form ={
              fundraise_id: this.fundraise_id,
              real_name: undefined,
              email: undefined,
              mobile_number: undefined,
              report_message: undefined,
              report_related_files: []
          }
          if (this.$refs["form"]) {
              this.$refs["form"].resetFields();
          }
      },
      submitForm: function() {
          this.$refs["form"].validate(valid => {
              if (valid) {
                  api.fundraiseBlacklist.addFundraiseBlacklist(this.form).then(response => {
                      this.dialogFormVisible = false;
                      this.$message.success('本平台已收到閣下的舉報');
                  });
              }
          });
      },
      otherHandleRemove(file, fileList) {
        let files = this.form.report_related_files;
        files.forEach((item, index) => {
          if (file.name == item.name) {
            files.splice(index, 1);
          }
        });
      },
      beforeAvatarUpload(file) {
        let types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
        let isUploadFile = types.includes(file.type);
        let fileSizeFlag = file.size / 1024 / 1024  < 2 ? true : false;
        if (! isUploadFile) {
          this.$message.error('上傳文件只能是jpg、png、jpeg、pdf格式');
          return false;
        }
        // if(! fileSizeFlag) {
        //   this.$message({message: '上傳文件大小不能超過2m',type: 'error'});
        //   return false;
        // }else{
        //   let formData = new FormData();
        //   formData.append("file", file);
        //   formData.append("source", '籌款上傳');
        //   let that = this;
        //          that.$axios({
        //             url:process.env.VUE_APP_API_SERVER+'/front/savefile/',
        //             method: 'post',
        //             data:formData,
        //             header: {
        //                 "Content-Type": "multipart/form-data"
        //             }          
        //         }).then(function(res){
        //             let save_file = {name: res.data.name, file: res.data.file_url};
        //             this.other_files.push(save_file);
        //             that.loading = false;
        //         }).catch(function(err){
        //             that.loading = false;
        //         })
        // }
        let formData = new FormData();
          formData.append("file", file);
          formData.append("source", '籌款上傳');
          let that = this;
                 that.$axios({
                    url:process.env.VUE_APP_API_SERVER+'/front/savefile/',
                    method: 'post',
                    data:formData,
                    header: {
                        "Content-Type": "multipart/form-data"
                    }          
                }).then(function(res){
                    let save_file = {name: res.data.data.name, file: res.data.data.file_url};
                    that.form.report_related_files.push(save_file);
                    that.loading = false;
                }).catch(function(err){
                    that.loading = false;
                })
      },
    }
};
</script>

<style lang="scss">
.el-upload--picture{
    text-align: left;
    margin-top:15px;
}
.el-button span{
    color:#fff
}
a{
    cursor:pointer;
}
.org-container {
    line-height: 1.3;
    border: 1px solid $red-500;
    padding: 16px;
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 80px auto auto;
    gap: 16px;
    .org-logo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        }
    .org-title-group {
        align-self: flex-end;
        .org-name {
            font-size: 24px;
            color: $green-500;
            font-weight: 600;
        }
        .org-sub-title {
            font-size: 14px;
            color: $green-500;
        }
    }
    .org-serial-number {
        grid-column: 1 / 3;
        font-weight: 600;
        color: $gray-400;
        word-break: break-all;
    }
    .org-detail {
        grid-column: 1 / 3;
        color: $gray-400;
    }
}
.org-containers {
    line-height: 1.3;
    padding: 16px;
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 1px auto auto;
    gap: 16px;
    .org-logo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        }
    .org-title-group {
        align-self: flex-end;
        .org-name {
            font-size: 24px;
            color: $green-500;
            font-weight: 600;
        }
        .org-sub-title {
            font-size: 14px;
            color: $green-500;
        }
    }
    .org-serial-number {
        grid-column: 1 / 3;
        font-weight: 600;
        color: $gray-400;
        word-break: break-all;
    }
    .org-detail {
        grid-column: 1 / 3;
        color: $gray-400;
    }
}
</style>